// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$kainet_web-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: mat.$azure-palette,
            tertiary: mat.$blue-palette,
        ),
        density: (
            scale: 0,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
    @include mat.all-component-themes($kainet_web-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($kainet_web-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($kainet_web-theme);
/* You can add global styles to this file, and also import other style files */
/* Global styles */
:root {
    --primary: #00cc99;
    --primary-variant: #00ac81;
    --secondary: #006649;
    --black-primary: #1a1a1a;
    --black-secondary: #2d2d2d;
    --platinum: #dcdbda;
    --white: #ffffff;
}

/* Custom Fonts*/
@font-face {
    font-family: 'Newake';
    src: url('/fonts/newake-font-regular.png') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/* CSS Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overscroll-behavior-y: none;

}

html,
body {
    height: 100%;
    font-family: 'Montserrat', sans-serif; /* Default font */
    scroll-behavior: auto;
    font-size: 20px; // Base de 1rem = 16px
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

a {
    text-decoration: none;
    color: inherit;
}

ul,
ol {
    list-style: none;
}

p {
    font-weight: 300;

}


// Estilos para elementos generales
h1, h2, h3, h4, h5, h6, p, ul, ol, a {
  margin: 0;
  padding: 0;
}

h1,.h1 {
  font-size: 4rem;    // 48px
  line-height: 1.2;   // Ajuste de altura de línea

  &._70{
    font-size: 3.5rem;
  }
}

h2,.h2 {
  font-size: 2.5rem;  // 40px
  line-height: 1.3;

  &._45{
    font-size: 2.25rem;
  }
}

h3,.h3 {
  font-size: 2rem;    // 32px
  line-height: 1.4;
}

h4, .h4 {
  font-size: 1.75rem; // 28px
  line-height: 1.4;
}

h5,.h5 {
  font-size: 1.5rem;  // 24px
  line-height: 1.4;
}

h6,.h6 {
  font-size: 1.25rem; // 20px
  line-height: 1.5;
}

p, .p {
  font-size: 1rem;    // 16px
  line-height: 1.6;

  &._30{
    font-size: 1.5rem;
  }

  &._20{
    font-size: 1rem;
  }

  &._16{
    font-size: 0.8rem;
  }
}

ul, ol,.ul,.ol {
  font-size: 1rem;    // 16px
  line-height: 1.6;
}

a,.a {
  font-size: 1rem;    // 16px
  text-decoration: none;
}

a:hover {
//   color: #0056b3;
}

@media (width <= 1600px) {

    html,
    body {
        font-size: 18px; // Base de 1rem = 16px
    }

    h1, .h1 {
        font-size: 3.5rem; // Reducción de tamaño para pantallas más pequeñas
        line-height: 1.2;

    &._70 {
        font-size: 3rem; // Ajuste para la clase _70
    }
    }

    h2, .h2 {
        font-size: 2.25rem; // Ajuste para pantallas más pequeñas
        line-height: 1.3;

    &._45 {
        font-size: 2rem; // Ajuste para la clase _45
    }
    }

    h3, .h3 {
        font-size: 1.75rem; // Ajuste para pantallas más pequeñas
        line-height: 1.4;
    }

    h4, .h4 {
        font-size: 1.5rem;  // Ajuste para pantallas más pequeñas
        line-height: 1.4;
    }

    h5, .h5 {
        font-size: 1.25rem; // Ajuste para pantallas más pequeñas
        line-height: 1.4;
    }

    h6, .h6 {
        font-size: 1rem;    // Ajuste para pantallas más pequeñas
        line-height: 1.5;
    }

    p, .p {
        font-size: 1.1rem;  // Ajuste para pantallas más pequeñas
        line-height: 1.6;

    &._30 {
        font-size: 1.25rem; // Ajuste para la clase _30
    }

    &._20 {
        font-size: 0.9rem; // Ajuste para la clase _20
    }

    &._16 {
        font-size: 0.75rem; // Ajuste para la clase _16
    }
    }

    ul, ol, .ul, .ol {
        font-size: 1.1rem;  // Ajuste para pantallas más pequeñas
        line-height: 1.6;

            &._30 {
                font-size: 1.25rem; // Ajuste para la clase _30
            }

            &._20 {
                font-size: 0.9rem; // Ajuste para la clase _20
            }

            &._16 {
                font-size: 0.75rem; // Ajuste para la clase _16
            }
        }
    

    a, .a {
        font-size: 0.9rem;  // Ajuste para pantallas más pequeñas
        text-decoration: none;
    }

    a:hover {
    // Puedes ajustar también los estilos de hover aquí si lo necesitas
    }
}


// Versiones móviles
@media (max-width: 920px) {

    html,body{
        font-size: 16px;
    }

  h1, .h1 {
    font-size: 2.5rem;   // 40px for mobile
    line-height: 1.2;

    &._70 {
      font-size: 3rem;  // Reduced for smaller devices
    }
  }

  h2, .h2 {
    font-size: 2rem;     // 32px for mobile
    line-height: 1.3;

    &._45 {
      font-size: 1.75rem; // 28px for mobile
    }
  }

  h3, .h3 {
    font-size: 1.5rem;   // 24px for mobile
    line-height: 1.4;
  }

  h4, .h4 {
    font-size: 1.25rem;  // 20px for mobile
    line-height: 1.4;
  }

  h5, .h5 {
    font-size: 1rem;     // 16px for mobile
    line-height: 1.4;
  }

  h6, .h6 {
    font-size: 0.875rem; // 14px for mobile
    line-height: 1.5;
  }

  p, .p {
    font-size: 1rem; // 14px for mobile
    line-height: 1.5;

    &._30 {
      font-size: 1.5rem; // Reduced for mobile (20px)
    }

    &._20 {
      font-size: 1rem;    // 16px for mobile
    }

    &._16 {
      font-size: 1rem; // 12px for mobile
    }
  }

  ul, ol, .ul, .ol {
    font-size: 0.875rem;  // 14px for mobile
    line-height: 1.6;
  }

  a, .a {
    font-size: 0.875rem;  // 14px for mobile
    text-decoration: none;
  }
}

/* Global styles*/

.contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contenido {
        width: 100%;
        max-width: 1600px;
        padding-left: 16px;
        padding-right: 16px;
    }

    @media (width <= 1600px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        .contenido {
            width: 100%;
            max-width: 1230px; // Ajuste para pantallas más pequeñas
        }
    }
}

.wrapper {
    display: flex;
    width: 100%;
    max-width: 1600px;
    padding-left: 16px;
    padding-right: 16px;

    @media (width <= 1600px) {
        max-width: 1230; // Ajuste para pantallas más pequeñas
        padding-left: 12px;  // Ajuste de padding
        padding-right: 12px; // Ajuste de padding
    }

    @media screen and (width > 920px) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    @media screen and (width <= 920px) {
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap
    }
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.strong {
    font-weight: 700;
}
.no-scroll {
    overflow: hidden;
}

.mat-form-field,
.mat-form-field * {
    font-family: 'Montserrat', sans-serif !important; // Aplícalo a todos los elementos dentro del mat-form-field
}


